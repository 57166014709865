import { render, staticRenderFns } from "./equipment-alarm-notification-panel.vue?vue&type=template&id=08c4f15b&scoped=true&"
import script from "./equipment-alarm-notification-panel.vue?vue&type=script&lang=js&"
export * from "./equipment-alarm-notification-panel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08c4f15b",
  null
  
)

export default component.exports
<template>
  <div v-if="isReady">
    <div class="overlay" v-if="busy">
      <i class="fa fa-refresh fa-spin"></i>
    </div>
    <h4>
      {{ title }}:
      <span class="text-primary"
        ><b>{{ equipment.name }}</b></span
      >
    </h4>
    <div class="box-body">
      <NotificationUserInputForm
        v-bind:defaultGroups="groups"
        v-bind:displayOrder="
          (panelOptions && panelOptions.displayOrder) || false
        "
        v-bind:deleteEnabled="deleteEnabled"
        v-bind:maxGroups="maxGroups"
        v-bind:allowNewGroup="false"
        v-model="groups"
      >
      </NotificationUserInputForm>
    </div>
    <div class="box-footer">
      <NotificationInlineForm
        v-bind:groups="groups"
        v-bind:timeout="timeout"
        v-on:submit="validate"
        v-on:cancel="cancel"
      >
      </NotificationInlineForm>
    </div>
  </div>
</template>

<script>
import NotificationUserInputForm from "@/components/notification-user-input-form";
import NotificationInlineForm from "@/components/notification-inline-form";
//import EquipmentSrv from "@/services/equipment.js";
import MixinAlert from "@/project/mixin-alert.js";
import ConnectorSrv from "@/services/connector.js";

export default {
  name: "EquipmentNotificationPanel",
  components: {
    NotificationUserInputForm,
    NotificationInlineForm
  },
  mixins: [MixinAlert],
  props: {
    equipment: {
      type: Object,
      required: true
    },
    display: {
      type: Object,
      required: true
    },
    panel: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data: function () {
    return {
      busy: false,
      isReady: false,
      groups: [],
      timeout:
        ("notifications" in this.$root.config.equipment_extended_properties &&
          this.$root.config.equipment_extended_properties.notifications
            .timeout) ||
        5
    };
  },
  computed: {
    title: function () {
      return (
        (this.panel && this.panel.title) || this.$t("equipment_notifications")
      );
    },
    panelOptions: function (name) {
      return (this.panel && this.panel.options) || {};
    },
    isValid: function () {
      return true; // TODO: check timeout and group list
    },
    deleteEnabled() {
      return (this.panelOptions && this.panelOptions.deleteEnabled) || false;
    },
    maxGroups() {
      return (this.panelOptions && this.panelOptions.maxGroups) || 5;
    }
  },
  watch: {
    groups: function (n, v) {
      this.$emit("input", {
        groups: n,
        timeout: this.timeout
      });
    },
    timeout: function (n, v) {
      this.$emit("input", {
        groups: this.groups,
        timeout: n
      });
    }
  },
  methods: {
    cancel: function () {
      let self = this;
      self.initData();
    },
    validate: function (entry) {
      let self = this;
      self.validatePrivileges("view", "NotificacoesEscrita").then((isOk) => {
        if (!isOk) {
          return;
        }
        if (self.isValid) {
          self.busy = true;
          self.service
            .get(self.equipment.id, self.$store.getters["user/contract_id"])
            .then((connector) => {
              if (connector) {
                //var srv = new EquipmentSrv();
                let payload = JSON.parse(JSON.stringify(connector));
                payload.user_data = payload.user_data || {};
                payload.user_data.extended_properties =
                  payload.user_data.extended_properties || {};
                payload.user_data.extended_properties.notifications =
                  payload.user_data.extended_properties.notifications || {};
                payload.user_data.extended_properties.notifications = {
                  timeout: entry.timeout,
                  groups: self.groups.map(function (item) {
                    return {
                      name: item.name,
                      users: (item.tags || []).filter((i) => i.indexOf("@") > 0)
                    };
                  }),
                  groups_telegram: self.groups.map(function (item) {
                    return {
                      name: item.name,
                      users: (item.tags || []).filter((i) => i.indexOf("@") < 0)
                    };
                  })
                };
                // local screen
                if (parseInt(payload.screen_id) > 999999999) {
                  payload.screen_id = null;
                }
                self.service.save(payload).then((ret) => {
                  self.busy = false;
                  self.validateSaveResponse(ret);
                  self.showAlert();
                });
                return;
              }
              self.busy = false;
            });
          return;
        }
      });
    },
    initGroups: function () {
      var source = [];
      var telegram_source = null;
      // BEGIN - fake telegram for testing
      // this.equipment.notifications.groups_telegram=JSON.parse(JSON.stringify(this.equipment.notifications.groups)); // fake it
      // this.equipment.notifications.groups_telegram.forEach((g)=>{g.users=[101,202,303,404,505];})
      // END
      if (
        this.equipment &&
        "notifications" in this.equipment &&
        "groups" in this.equipment.notifications &&
        this.equipment.notifications.groups.length
      ) {
        source = this.equipment.notifications.groups;
        telegram_source = this.equipment.notifications.groups_telegram || [];
      } else {
        if ("groups" in this.panelOptions && this.panelOptions.groups.length) {
          source = this.panelOptions.groups;
        }
      }
      var groups = source.map(function (i, ix) {
        var group = {
          name: i.name,
          tags: (("users" in i && i.users) || []).map(function (user) {
            return {text: user};
          }),
          fixed: false
        };
        if (telegram_source && ix < telegram_source.length) {
          let ii = telegram_source[ix];
          let tags = (("users" in ii && ii.users) || []).map(function (user) {
            return {text: user};
          });
          if (tags.length) {
            group.tags = group.tags.concat(tags);
          }
        }
        return group;
      });
      this.$set(this, "groups", groups);
    },
    initTimeout: function () {
      var source = [];
      if (
        this.equipment &&
        "notifications" in this.equipment &&
        "timeout" in this.equipment.notifications &&
        this.equipment.notifications.timeout
      ) {
        this.timeout = this.equipment.notifications.timeout;
      } else {
        if ("timeout" in this.panelOptions && this.panelOptions.timeout) {
          this.timeout = this.panelOptions.timeout;
        }
      }
    },
    initData() {
      let self = this;
      self.isReady = false;
      if (self.panelOptions) {
        self.initGroups();
        self.initTimeout();
        self.$nextTick(function () {
          self.isReady = true;
        });
      }
    }
  },
  created() {
    this.service = new ConnectorSrv();
  },
  mounted: function () {
    var self = this;
    self.initData();
  }
};
</script>

<style scoped></style>
